/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface DataIdTypeResponseDto { 
    id: string;
    type: DataIdTypeResponseDto.TypeEnum;
}
export namespace DataIdTypeResponseDto {
    export type TypeEnum = 'NEW' | 'DONOR';
    export const TypeEnum = {
        New: 'NEW' as TypeEnum,
        Donor: 'DONOR' as TypeEnum
    };
}


