import { Injectable, Injector } from '@angular/core';
import {
  BehaviorSubject,
  filter,
  map,
  mergeMap,
  Observable,
  OperatorFunction,
  take,
} from 'rxjs';

import { TenantIdService } from './tenant-id.service';

@Injectable({ providedIn: 'root' })
export abstract class AbstractControllerService {
  private tenantId: BehaviorSubject<undefined | string>;

  constructor(injector: Injector) {
    this.tenantId = injector.get(TenantIdService).tenantId;
  }

  protected getTenantIdWithPayload<T>(
    observable: (tenantId?: string) => Observable<T>,
  ): Observable<T> {
    return this.tenantId.pipe(
      filter((tenantId) => tenantId !== undefined),
      take(1),
      mergeMap((tenantId) => observable(tenantId || undefined)),
    );
  }

  protected getTenantId<T>(
    observable: (tenantId?: string) => Observable<{ payload: T }>,
  ): Observable<T> {
    return this.getTenantIdWithPayload(observable).pipe(this.responseHandle());
  }

  protected responseHandle<T>(): OperatorFunction<{ payload: T }, T> {
    return (input$) => input$.pipe(map((apiResponse) => apiResponse.payload));
  }
}
