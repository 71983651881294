import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';

import { LanguageResourceDto, ResourceService } from '../../openapi';
import { AbstractControllerService } from './abstract-controller.service';

@Injectable({ providedIn: 'root' })
export class ResourceControllerService extends AbstractControllerService {
  constructor(injector: Injector, private resourceService: ResourceService) {
    super(injector);
  }

  getAllForModuleAndLanguage(
    module: string,
    language: string,
  ): Observable<LanguageResourceDto> {
    return this.getTenantId((tenantId) => this.resourceService.getAllForModuleAndLanguage(
      module,
      language,
      tenantId,
    ));
  }

  getMediaAsText(mediaId: string): Observable<string> {
    return this.getMediaById<string>(mediaId, 'text');
  }

  getMediaAsBlob(mediaId: string): Observable<Blob> {
    return this.getMediaById<Blob>(mediaId, 'blob');
  }

  private getMediaById<T>(
    mediaId: string,
    responseType: string,
  ): Observable<T> {
    return this.getTenantIdWithPayload((tenantId) => this.resourceService.getMediaById(mediaId, tenantId, 'body', false, { httpHeaderAccept: responseType as 'application/json' })) as Observable<T>;
  }
}
