import { Injectable, Injector } from '@angular/core';
import { map, Observable } from 'rxjs';

import {
  ApiGatewayProcessResponseDto,
  ConfigureApiGatewayRequestDto,
  TenantConfigurationDto,
  TenantConfigurationRequestDto,
  TenantConfigurationService,
} from '../../openapi';
import { AbstractControllerService } from './abstract-controller.service';

const PRIVATE_FIELDS = [
  'haemocloudCustomerKey',
  'backendGoogleApiKey',
  'tenantApiKey',
  'device-360AlternateStorageLocation',
];

@Injectable({ providedIn: 'root' })
export class TenantConfigurationControllerService extends AbstractControllerService {
  constructor(
    injector: Injector,
    private tenantConfigurationService: TenantConfigurationService,
  ) {
    super(injector);
  }

  configureApiGateway(
    configureApiGatewayRequestDto: ConfigureApiGatewayRequestDto,
  ): Observable<ApiGatewayProcessResponseDto> {
    return this.tenantConfigurationService
      .configureApiGateway(configureApiGatewayRequestDto)
      .pipe(this.responseHandle());
  }

  getApiGatewayConfigurationProcessStatus(
    processIds: string[],
  ): Observable<ApiGatewayProcessResponseDto[]> {
    return this.tenantConfigurationService
      .getApiGatewayConfigurationProcessStatus(processIds)
      .pipe(this.responseHandle());
  }

  findPrivateByCurrentTenant(id?: string): Observable<Record<string, string>> {
    return (
      id
        ? this.tenantConfigurationService
          .findPrivateByCurrentTenant(id)
          .pipe(this.responseHandle())
        : this.getTenantId((tenantId) => this.tenantConfigurationService.findPrivateByCurrentTenant(
          tenantId,
        ))
    ).pipe(
      map((configuration) => this.getCamelCaseConfigurations(configuration.configMap)),
    );
  }

  findPublicByCurrentTenant(id?: string): Observable<Record<string, string>> {
    return (
      id
        ? this.tenantConfigurationService
          .findPublicByCurrentTenant(id)
          .pipe(this.responseHandle())
        : this.getTenantId((tenantId) => this.tenantConfigurationService.findPublicByCurrentTenant(tenantId))
    ).pipe(
      map((configuration) => this.getCamelCaseConfigurations(configuration.configMap)),
    );
  }

  saveTenantConfiguration(
    tenantConfigurations: Record<string, string>,
    tenantId?: string,
  ): Observable<TenantConfigurationDto[]> {
    return this.tenantConfigurationService
      .saveTenantConfiguration(
        {
          tenantConfigurationList:
            this.getSnakeCaseConfigurations(tenantConfigurations),
        },
        tenantId,
      )
      .pipe(this.responseHandle());
  }

  updateApiGatewayConfiguration(
    configureApiGatewayRequestDtos: ConfigureApiGatewayRequestDto[],
  ): Observable<ApiGatewayProcessResponseDto[]> {
    return this.tenantConfigurationService
      .updateApiGatewayConfiguration(configureApiGatewayRequestDtos)
      .pipe(this.responseHandle());
  }

  private getCamelCaseConfigurations(
    configurations: Record<string, TenantConfigurationDto>,
  ): Record<string, string> {
    return Object.values(configurations || {}).reduce<Record<string, string>>(
      (acc, { key, value }) => {
        acc[key.replace(/[-_][a-z]/g, (m) => m.slice(-1).toUpperCase())] = value;
        return acc;
      },
      {},
    );
  }

  private getSnakeCaseConfigurations(
    configurations: Record<string, string>,
  ): TenantConfigurationRequestDto[] {
    return Object.entries(configurations).map(([key, value]) => ({
      key: key.replace(/[A-Z]/g, (m) => `-${m.toLowerCase()}`),
      value,
      isPublic: !PRIVATE_FIELDS.includes(key),
    }));
  }
}
