import { Injectable, Injector } from '@angular/core';
import { catchError, map, Observable, of } from 'rxjs';

import {
  ChangeUserEmailDto,
  CreateUserAgreementsListDto,
  FcmTokenDto,
  PrivacyPolicyResponseDto,
  UserPreferencesDto,
  UserService as APIUserService,
  VerifyEmailDto,
} from '../../openapi';
import { AbstractControllerService } from './abstract-controller.service';

@Injectable({ providedIn: 'root' })
export class UserService extends AbstractControllerService {
  constructor(
    injector: Injector,
    private userService: APIUserService,
  ) {
    super(injector);
  }

  changeUserEmail(changeUserEmailDto: ChangeUserEmailDto) {
    return this.userService.changeUserEmail(changeUserEmailDto);
  }

  createUserPreferences(
    userPreferencesDto: UserPreferencesDto,
  ): Observable<UserPreferencesDto> {
    return this.userService
      .createUserPreferences(userPreferencesDto)
      .pipe(this.responseHandle());
  }

  getUserAgreements(): Observable<PrivacyPolicyResponseDto[]> {
    return this.userService.getUserAgreements().pipe(
      this.responseHandle(),
      map((payload) => payload.privacyPolicyResponseDtoList),
    );
  }

  getUserPreferences(): Observable<UserPreferencesDto | undefined> {
    return this.userService.getUserPreferences().pipe(
      map(({ payload }) => payload),
      catchError(() => of(undefined)),
    );
  }

  remove() {
    return this.userService.remove();
  }

  saveUserAgreements(
    createUserAgreementsListDto: CreateUserAgreementsListDto,
  ): Observable<PrivacyPolicyResponseDto[]> {
    return this.userService
      .saveUserAgreements(createUserAgreementsListDto)
      .pipe(
        this.responseHandle(),
        map((payload) => payload.privacyPolicyResponseDtoList),
      );
  }

  storeUserFcmToken(fcmTokenDto: FcmTokenDto) {
    return this.userService.storeUserFcmToken(fcmTokenDto);
  }

  updateUserPreferences(
    userPreferencesDto: UserPreferencesDto,
  ): Observable<UserPreferencesDto> {
    return this.userService
      .updateUserPreferences(userPreferencesDto)
      .pipe(this.responseHandle());
  }

  verifyUserEmail(verifyEmailDto: VerifyEmailDto) {
    return this.userService.verifyUserEmail(verifyEmailDto);
  }

  getUserIdentityProviderInfo(email: string) {
    return this.userService.getUserIdentityProviderInfo(email);
  }

  initiatePasswordReset(email: string, deliveryMethod: 'SMS'|'MAIL') {
    return this.userService.initiatePasswordReset(email, deliveryMethod);
  }

  changePassword(userId:string, authenticationTicket:string, newPassword:string) {
    return this.userService.changePassword({
      userId,
      authenticationTicket,
      newPassword,
    });
  }
}
