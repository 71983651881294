import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';

import {
  EnvironmentResponseDto,
  InformationService,
  ReferralInfoDto,
  TenantResponseDto,
} from '../../openapi';
import { AbstractControllerService } from './abstract-controller.service';

@Injectable({ providedIn: 'root' })
export class InformationControllerService extends AbstractControllerService {
  constructor(
    injector: Injector,
    private informationService: InformationService,
  ) {
    super(injector);
  }

  getCurrentTenant(): Observable<TenantResponseDto> {
    return this.getTenantId((tenantId) => this.informationService.getCurrentTenant1(tenantId));
  }

  getEnvironmentPrefix(): Observable<EnvironmentResponseDto> {
    return this.informationService
      .getEnvironmentPrefix()
      .pipe(this.responseHandle());
  }

  getBecsLanguageInfo(): Observable<string[]> {
    return this.getTenantId((tenantId) => this.informationService.getBecsLanguageInfo(tenantId));
  }

  getReferralInfo(referralCode: string): Observable<ReferralInfoDto> {
    return this.getTenantId((tenantId) => this.informationService.getReferralInfo(referralCode, tenantId));
  }
}
