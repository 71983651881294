/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { QuestionResources } from './question-resources';
import { QuestionConditions } from './question-conditions';
import { Option } from './option';


export interface Question { 
    id: string;
    text: string;
    helpText: string;
    canRequestHelp: boolean;
    type: Question.TypeEnum;
    highlight: boolean;
    options: Array<Option>;
    conditions: QuestionConditions;
    resources: QuestionResources;
}
export namespace Question {
    export type TypeEnum = 'single' | 'multi';
    export const TypeEnum = {
        Single: 'single' as TypeEnum,
        Multi: 'multi' as TypeEnum
    };
}


