import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { catchError, map, Observable } from 'rxjs';

import {
  CalculatedCoordinatesResponseDto,
  CreateUpdateDonorNexlynkRequestDto,
  DataIdTypeResponseDto,
  DonationCenterResponseDto,
  DonorConfirmResponseDto,
  DonorDonationsResponseDto,
  DonorDueActivitiesDto,
  DonorLookupRequestDto,
  DonorLookupResultResponseDto,
  DonorNextPaymentValueResponseDto,
  DonorQueueContactedOptionRequestDto,
  DonorQueueInformationResponseDto,
  DonorService,
  DonorVerificationRequestDto,
  InterviewsSubmission,
  InterviewSubmissionRequestDto,
  OctapharmaSiteDetailResponseDto,
  ProfileResponseDto,
  ServerResponseVoid,
  SiteStationsResponseItemDto,
  TransferLoginRequestDto,
} from '../../openapi';
import { AbstractControllerService } from './abstract-controller.service';

@Injectable({ providedIn: 'root' })
export class DonorControllerService extends AbstractControllerService {
  constructor(injector: Injector, private donorService: DonorService) {
    super(injector);
  }

  deleteDonorProfile(): Observable<ServerResponseVoid> {
    return this.getTenantIdWithPayload((tenantId) => this.donorService.deleteDonorProfile(tenantId));
  }

  findRefreshTokenById(tokenId: string): Observable<TransferLoginRequestDto> {
    return this.getTenantId((tenantId) => this.donorService.findRefreshTokenById(tokenId, tenantId));
  }

  getAllDonationsCenters(): Observable<DonationCenterResponseDto[]> {
    return this.getTenantId((tenantId) => this.donorService.getAllDonationsCenters(tenantId));
  }

  getAllSites(): Observable<SiteStationsResponseItemDto[]> {
    return this.getTenantId((tenantId) => this.donorService.getAllSites(tenantId));
  }

  getAllSitesNearLocation(
    latitude: number,
    longitude: number,
  ): Observable<CalculatedCoordinatesResponseDto[]> {
    return this.getTenantId((tenantId) => this.donorService.getAllSitesNearLocation(
      {
        longitude,
        latitude,
      },
      tenantId,
    )).pipe(
      map(({ calculatedDistanceAndCoordinatesResponseItems }) => calculatedDistanceAndCoordinatesResponseItems.map(
        (station) => station.calculatedCoordinates,
      )),
    );
  }

  getAllSitesWithCoordinates(
    zipCode?: string,
  ): Observable<CalculatedCoordinatesResponseDto[]> {
    return this.getTenantId((tenantId) => this.donorService.getAllSitesWithCoordinates(tenantId, zipCode)).pipe(map(({ sitesWithCoordinatesList }) => sitesWithCoordinatesList));
  }

  getDonationHistory(): Observable<DonorDonationsResponseDto> {
    return this.getTenantId((tenantId) => this.donorService.getDonationHistory(tenantId));
  }

  getDonorNextPaymentAmount(): Observable<DonorNextPaymentValueResponseDto> {
    return this.getTenantIdWithPayload((tenantId) => this.donorService.getDonorNextPaymentAmount(tenantId));
  }

  getTransferDonationSites(): Observable<DonationCenterResponseDto[]> {
    return this.getTenantId((tenantId) => this.donorService.getDonorQuickTransferCentersBySiteId(tenantId));
  }

  getDonorsDueActivities(): Observable<
    DonorDueActivitiesDto.DueActivityTypesEnum[]
    > {
    return this.getTenantIdWithPayload((tenantId) => this.donorService.getDonorsDueActivities(tenantId)).pipe(map(({ dueActivityTypes }) => dueActivityTypes));
  }

  getDonorsQueue(
    siteId: string,
  ): Observable<DonorQueueInformationResponseDto[]> {
    return this.getTenantId((tenantId) => this.donorService.getDonorsQueue(siteId, tenantId));
  }

  getInterviewSubmission(): Observable<InterviewsSubmission> {
    return this.getTenantId((tenantId) => this.donorService.getInterviewSubmissionIdForCurrentDonor(tenantId));
  }

  getInterviewSubmissionId(): Observable<DataIdTypeResponseDto> {
    return this.getTenantId((tenantId) => this.donorService.getInterviewSubmissionIdForCurrentUser(tenantId));
  }

  getProfile(): Observable<ProfileResponseDto> {
    return this.getTenantId((tenantId) => this.donorService.getProfile(tenantId)).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 404) {
          return this.createProfile();
        }
        throw error;
      }),
    );
  }

  getSiteDetails(id: string): Observable<OctapharmaSiteDetailResponseDto> {
    return this.getTenantId((tenantId) => this.donorService.getSiteDetails(id, tenantId));
  }

  getLookupDonorByDonorToken(
    donorToken: string,
  ): Observable<DonorLookupResultResponseDto> {
    return this.getTenantId((tenantId) => this.donorService.lookupDonorByDonorToken(donorToken, tenantId));
  }

  getLookupDonor(): Observable<DonorLookupResultResponseDto> {
    return this.getTenantId((tenantId) => this.donorService.lookupDonorById(tenantId));
  }

  createLookupDonor(
    donorLookupRequestDto: DonorLookupRequestDto,
  ): Observable<DonorLookupResultResponseDto> {
    return this.getTenantId((tenantId) => this.donorService.lookupDonorByPII(donorLookupRequestDto, tenantId));
  }

  postCreateUpdateDonorNexlynk(
    createUpdateDonorNexlynkRequestDto: CreateUpdateDonorNexlynkRequestDto,
  ): Observable<ServerResponseVoid> {
    return this.getTenantIdWithPayload((tenantId) => this.donorService.postCreateUpdateDonorNexlynk(
      createUpdateDonorNexlynkRequestDto,
      tenantId,
    ));
  }

  putDonorQueueContactOption(
    donorQueueContactedOptionRequestDto: DonorQueueContactedOptionRequestDto,
  ): Observable<void> {
    return this.getTenantIdWithPayload((tenantId) => this.donorService.putDonorQueueContactOption(
      donorQueueContactedOptionRequestDto,
      tenantId,
    ));
  }

  putInterviewSubmission(
    interviewSubmissionRequestDto: InterviewSubmissionRequestDto,
  ): Observable<DataIdTypeResponseDto> {
    return this.getTenantId((tenantId) => this.donorService.putInterviewSubmission(
      interviewSubmissionRequestDto,
      tenantId,
    ));
  }

  submitReferralCode(referralCode: string): Observable<ServerResponseVoid> {
    return this.getTenantIdWithPayload((tenantId) => this.donorService.submitReferralCode({ referralCode }, tenantId));
  }

  verifyDonor(
    donorVerificationRequestDto: DonorVerificationRequestDto,
  ): Observable<DonorConfirmResponseDto> {
    return this.getTenantId((tenantId) => this.donorService.verifyDonor(donorVerificationRequestDto, tenantId));
  }

  private createProfile(): Observable<ProfileResponseDto> {
    return this.getTenantId((tenantId) => this.donorService.createProfile(tenantId));
  }
}
