import { Injectable, Injector } from '@angular/core';

import { ConfirmationService } from '../../openapi';
import { AbstractControllerService } from './abstract-controller.service';

export type ConfirmationData= {
  status: 'SMS' | 'MAIL' | 'CONFIRMED' | 'NEW',
  error?: string
}

@Injectable({ providedIn: 'root' })
export class ConfirmationControllerService extends AbstractControllerService {
  constructor(injector: Injector, private confirmationService: ConfirmationService) {
    super(injector);
  }

  confirmCode(deliveryMethod: 'SMS' | 'MAIL', code: string) {
    return this.getTenantId((tenantId) => this.confirmationService.confirmIdentity(deliveryMethod, code, tenantId));
  }

  initiateConfirmationForType(deliveryMethod: 'SMS' | 'MAIL') {
    return this.getTenantId((tenantId) => this.confirmationService.initiateIdentityConfirmationForType(deliveryMethod, tenantId));
  }

  verifyPasswordReset(confirmationCodeId:string, code:string) {
    return this.getTenantId((tenantId) => this.confirmationService.verifyPasswordReset({ confirmationCodeId, code }, tenantId));
  }
}
